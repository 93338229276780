export const LOAD_SUPPORT_START = 'LOAD_SUPPORT_START';
export const LOAD_SUPPORT_FINISH = 'LOAD_SUPPORT_FINISH';
export const LOAD_SUPPORT_FAIL = 'LOAD_SUPPORT_FAIL';

export type LOAD_SUPPORT_START_TYPE = typeof LOAD_SUPPORT_START;
export type LOAD_SUPPORT_FINISH_TYPE = typeof LOAD_SUPPORT_FINISH;
export type LOAD_SUPPORT_FAIL_TYPE = typeof LOAD_SUPPORT_FAIL;

export interface ILoadSupportStartAction {
    type: LOAD_SUPPORT_START_TYPE;
    payload: {
        lang: string;
    }
}

export interface ILoadSupportFinishAction {
    type: LOAD_SUPPORT_FINISH_TYPE;
    lang: string;
    text: string;
}

export interface ILoadSupportFailAction {
    type: LOAD_SUPPORT_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type SupportAction = ILoadSupportStartAction | ILoadSupportFinishAction | ILoadSupportFailAction;
