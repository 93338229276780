import React, {ReactNode, useEffect} from 'react';
import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {RouteComponentProps} from 'react-router';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import {PRESS_ALIAS} from '../../constants/constants';
import {useAudio} from '../../hooks/useAudio';
import {ICategory, IMusicTop, IPlayerInfo, Languages} from '../../types/types';
import {CategoryPage, ICategoryPageRouteProps} from '../CategoryPage/CategoryPage';
import {ContentPageContainer, IContentPageRouteProps} from '../ContentPage/ContentPageContainer';
import {ErrorPage} from '../ErrorPage/ErrorPage';
import {FooterContainer} from '../Footer/FooterContainer';
import {MainPage} from '../MainPage/MainPage';
import {OfferPageContainer} from '../OfferPage/OfferPageContainer';
import {Player} from '../Player/Player';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';
import {
    IPressContentPageRouteProps,
    PressContentPageContainer,
} from '../Press/PressContentPage/PressContentPageContainer';

import './App.css';

export interface IAppStateProps {
    datas: {
        [lang: string]: ICategory[] | [] | undefined;
    }
    data: ICategory[] | [] | undefined;
    musicData: ICategory[] | [];
    musicTops: IMusicTop[] | [];
    playerInfo: IPlayerInfo;
    categoryLoaded: boolean;
    error?: boolean;
    privacyText: string;
    privacyTexts: {
        [key: string]: string;
    };
    textLoaded: boolean;
    authorised?: boolean;
    lang: Languages;
}

export interface IAppDispatchProps {
    onInit(lang: Languages): void;
    onLoad(lang: Languages): void;
    onChange(lang: Languages): void;
}

export interface IAppProps extends IClassNameProps, IAppStateProps, IAppDispatchProps {
}

const app: ClassNameFormatter = cn('App');

export const App = (props: IAppProps) => {
    const { error, categoryLoaded, data, lang, datas, onChange, authorised } = props;

    const [, handlePlay, handlePause] = useAudio();

    useEffect(() => {
        props.onLoad(lang);
        props.datas[lang] === undefined &&
        props.onInit(lang);
    }, [lang]);

    const renderMainPage = (properties: RouteComponentProps): ReactNode => {
        if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        } else if (error || !categoryLoaded || data && data[0] === undefined) {
            return <ErrorPage/>
        } else if (categoryLoaded && datas[lang] !== undefined) {
            return (
                <MainPage
                    categoriesData={data}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                />
            )
        }
        return (<HorizontalPreloader/>);
    };

    const renderCategoryPage = (properties: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <CategoryPage {...properties} categoriesData={data}/>
            )
        } else if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    const renderContentPage = (properties: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <ContentPageContainer {...properties} categoriesData={data} onLoad={() => {
                }} onCheck={() => {
                }}/>
            )
        } else if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    const renderPressContentPage = (properties: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
        if (data) {
            return (
                <PressContentPageContainer {...properties} categoriesData={data} onLoad={() => {
                }}/>
            )
        } else {
            return null;
        }
        return (<HorizontalPreloader/>);
    };

    // const redirectToOffer = () => {
    //     return (<Redirect to={'/offer'} />)
    // };

    const renderOffer = (): ReactNode => {
        return (
            <OfferPageContainer onLoad={(language: Languages) => {
            }} categoriesData={data}/>
        )
    };

    const renderFooter = (): ReactNode => {
        if (data) {
            return (
                <FooterContainer onLoad={(language: Languages) => {
                }} categoriesData={data}/>
            )
        } else {
            return null;
        }
    };

    const createPrivacyText = (htmlstring: string) => {
        const regExp = /%.*%/;
        const match = htmlstring.match(regExp);
        const substr = match ? match[0] : '';
        const linkName = substr ? substr.slice(1, substr.length - 1) : '';
        const linkHtml = `<a href="http://lk.funzone.by/privacypolicy/?service=rb-mts-video">
                        ${linkName}</a>`;
        return htmlstring.replace(substr, linkHtml);
};

    const renderPrivacy = (): ReactNode => {
        if (props.privacyText) {
            return (
                <PrivacyPolicy dangerousText={createPrivacyText(props.privacyText)} adoption={true} />
            )
        }
    }



    // const lastUrl: string | undefined = Cookies.get('lastUrl');
    // const redirect = lastUrl ? <Redirect from='/' to={lastUrl}/> : null;
    return (
        <div className={app()} key={lang}>
            <BrowserRouter>
                <div style={(!authorised) ? {userSelect : 'none'} : undefined} className={app('Wrapper')}>
                    <Switch>
                        <Route exact={true} path={'/'}
                               render={(properties: RouteComponentProps): ReactNode => {
                                   return renderMainPage(properties);
                               }}/>

                        <Route exact={true} path='/category/:alias'
                               render={(properties: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
                                   return renderCategoryPage(properties);
                               }}/>
                        <Route exact={true} path={`/category/${PRESS_ALIAS}/content/:id`}
                               render={(properties: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
                                   return renderPressContentPage(properties);
                               }}/>
                        <Route exact={true} path='/category/:alias/content/:id'
                               render={(properties: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
                                   return renderContentPage(properties);
                               }}/>
                        <Route path='/oferta'
                               render={(): ReactNode => {
                                   return renderOffer();
                               }}/>
                    </Switch>
                    {renderPrivacy()}
                    <Switch>
                        <Route path={`/category/${PRESS_ALIAS}/content/:id`} render={(): null => {
                            return null;
                        }}/>
                        <Route path='*'
                               render={(): ReactNode => {
                                   return renderFooter();
                               }}/>
                    </Switch>
                    <Player
                        handlePlay={handlePlay}
                        handlePause={handlePause}
                    />
                </div>
            </BrowserRouter>
        </div>
    );
}
