import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import { Translation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom';

import {getContentLink} from '../../helpers/getLinks';
import {ContentType, IPreview, ITag} from '../../types/types';
import {ContentDescription} from '../ContentDescription/ContentDescription';
import play from '../Icons/play-button.svg';

import './ContentItemPreview.css';

export interface IContentItemPreviewStateProps {
    authorised?: boolean;
}

export interface IContentItemPreviewProps extends IClassNameProps, IContentItemPreviewStateProps {
    id: number;
    title: string;
    description?: string;
    previews: IPreview[];
    type: ContentType.type1 | ContentType.type2 | ContentType.type3 | ContentType.type4;
    tags: ITag[];
    key: number;
    order: number;
    url: string;
    categoryAlias: string;
    page: string;
    uidx: string;
    size: string;
}

const contentItemPreview: ClassNameFormatter = cn('ContentItemPreview');

export class ContentItemPreview extends Component<IContentItemPreviewProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getContentType = (contentType: string): ReactNode => {
        if (contentType === ContentType.type1) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Читать далее')}</div>
                    }
                </Translation>
            );
        } else if (contentType === ContentType.type2 || contentType === ContentType.type4) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Смотреть видео')}</div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    };

    getIcon = (contentType: string): string => {
        if (contentType === ContentType.type1) {
            return 'read'
        } else if (contentType === ContentType.type2 || contentType === ContentType.type4) {
            return 'video'
        } else {
            return '';
        }
    };

    render() {
        const {tags, type, order, id, categoryAlias, previews, title, page, authorised, size} = this.props;
        const contentType = this.getContentType(type);
        const icon = this.getIcon(type);
        const notCopy = !authorised;
        let preview;
        previews[1] ? preview = previews[1] : preview = previews[0];
        const contentLink = getContentLink(categoryAlias, id);

        if (preview) {
            let backgroundImage: string;
            let backgroundSize: string;

            if (type === ContentType.type2 || type === ContentType.type4) {
                backgroundImage = `url(${play}), url(/storage/d/${preview.link})`;
                backgroundSize = '50px, cover';

            } else {
                backgroundImage = `url(/storage/d/${preview.link})`;
                backgroundSize = 'cover';
            }

            const itemStyle = {
                backgroundImage,
                backgroundSize,
                backgroundColor: '#dbdbdb',
                backgroundPositionY: '50%, 50%',
                backgroundPositionX: '50%, 50%',
                backgroundRepeat: 'no-repeat',
                pointerEvent: !authorised && 'none',
            };

            return (
                <div
                    className={classnames(
                        contentItemPreview({
                            type,
                            ofPage: page,
                            premium: !authorised,
                            size,
                            order: String(order),
                        }),
                        this.props.className)}>
                    <Link to={contentLink} className={contentItemPreview('Link')}>
                        <div className={contentItemPreview('Info')}>
                            <div className={contentItemPreview('ImageWrap')}>
                                {icon === 'video' ? <div className={contentItemPreview('Cart')} /> : <div />}
                                <div className={contentItemPreview('Button')} >
                                    <div
                                        className={classnames(contentItemPreview('ButtonText'),
                                            contentItemPreview('type-' + icon))}>{contentType}
                                    </div>
                                </div>
                                <LazyLoad scroll={true}>
                                    <div style={itemStyle} className={contentItemPreview('Image')}/>
                                </LazyLoad>
                            </div>
                            <ContentDescription notCopy={notCopy} className={contentItemPreview('Description')}
                                                tags={tags} title={title}
                                                link={contentLink} type={type} size={size} page={page}/>
                        </div>
                    </Link>
                </div>
            )
        }

        return null;
    }
}
