export const dictionary = {
    'ru-RU': {
        translation: {
            'Пресса': 'Журналы',
            'Про спорт': 'Спорт',
            'Про автомобили': 'Авто',
            'Про стиль': 'Стиль',
            'Про гаджеты': 'Гаджеты',
            'Про отдых': 'Отдых',
            'Про космос': 'Космос',
            'Про питание': 'Питание',
            'ЛК': 'Управление услугой в',
            'Ссылка': 'Личном кабинете',
        },
    },
};
