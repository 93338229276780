import {Dispatch} from 'redux';
import {LOAD_FOOTER_START} from '../../actions/footerAction';
import {LOAD_SUPPORT_START} from '../../actions/supportAction';
import {IAppStore, ICategory, Languages} from '../../types/types';
import {CHANGE_LANGUAGE_START} from '../../actions/languageAction';
import {connect} from 'react-redux';
import {Header, IHeaderStateProps} from './Header';

export interface IOwnProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IHeaderStateProps => {
    return {
        ...ownProps,
        language: store.lang,
        supportText: store.supportText.text[store.lang],
        supportTexts: store.supportText.text,
        error: store.supportText.error,
        textLoaded: store.supportText.textLoaded,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (lang: Languages) => {
        dispatch({type: LOAD_SUPPORT_START, payload: {
                lang,
            }})
    },
    onChange: (lang: Languages) => {
        dispatch({type: CHANGE_LANGUAGE_START, payload: lang})
    },
});

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
