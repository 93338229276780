import React, {useEffect, useState} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import Cookie from 'js-cookie';
import {Translation} from 'react-i18next';
import LazyLoad from 'react-lazyload';
import {useDispatch, useSelector} from 'react-redux';

import {setPlayerInfo} from '../../actions/playerAction';
import {AUTH_LINK_RU} from '../../constants/constants';
import {IContentInfo, IMusicTop, Languages} from '../../types/types';
import {IAppStore} from '../../types/types';
import {ITrackInfo} from '../../types/types';
import {Equalizer} from '../Equalizer/Equalizer';
import arrow from '../Icons/arrowNextTrack.svg';
import placeholder from '../Icons/placeholder_music.svg';
import play from '../Icons/play.svg';

import './MusicItemPreview.css';

interface IMusicItemPreview {
    playlist: IContentInfo[];
    alias: string;
    lang: string;
    authorized: boolean;
    handlePlay(): void;
    handlePause(): void;
}

const musicItemPreview: ClassNameFormatter = cn('MusicItemPreview');

export const getNearNumber = (actualTrackNumber: number, playListLength: number, direction: number = 1): number => {
    const newTrackNumber = actualTrackNumber + direction;
    if (newTrackNumber >= playListLength || newTrackNumber < 0) {
        return actualTrackNumber;
    }
    return actualTrackNumber + direction;
};

export const getNextTopNumber = (topNumber: number, tops: IMusicTop[]): number => {
    const nextTopNumber = getNearNumber(topNumber, tops.length, 1);
    if (topNumber === nextTopNumber) {
        return 0;
    }
    return nextTopNumber;
}

export const getTrackInfo = (list: IContentInfo[], numberOfTrack: number): ITrackInfo => {
    const nameOfTrack: string = list[numberOfTrack] ? list[numberOfTrack].name : '';
    const nameOfPlayer: string = list[numberOfTrack] ? list[numberOfTrack].description : '';
    const previewSrc: string = (list[numberOfTrack] && list[numberOfTrack].previews
        && list[numberOfTrack].previews.length) ?
        `url(/storage/d/${list[numberOfTrack].previews[0].link})` : '';
    const trackSrc: string = (list[numberOfTrack] && list[numberOfTrack].uidx && list[numberOfTrack].file_hash) ?
        `/gate/d/${list[numberOfTrack].uidx}/${list[numberOfTrack].file_hash}` : '';
    return {
        trackName: nameOfTrack,
        playerName: nameOfPlayer,
        trackLink: trackSrc,
        previewLink: previewSrc,
    }
}

export const MusicItemPreview = (props: IMusicItemPreview) => {
    const {
        playlist,
        alias,
        lang,
        authorized,
        handlePlay,
        handlePause,
    } = props;
    const state: IAppStore = useSelector((st: IAppStore) => st);

    const {
        playerInfo: {
            trackNumber,
            topNumber,
            musicAlias,
            isPlaying,
        },
        section: {
            musicTops,
        },
    } = state;

    const [currentTrackNumber, setCurrentTrackNumber] = useState(0);
    const currentTopNumber: number = musicTops
        .findIndex((musicTop) => musicTop.alias === alias) > -1 ? musicTops
        .findIndex((musicTop) => musicTop.alias === alias) : 0;

    useEffect(() => {
        setCurrentTrackNumber(trackNumber);
        if (alias === musicAlias) {
            // setCurrentTrackNumber(trackNumber);
            if (trackNumber === playlist.length - 1) {
                setAvailableNextTrackInTop(false);
            } else {
                setAvailableNextTrackInTop(true);
            }
        }
    }, [trackNumber, musicAlias]);

    const [availableNextTrackInTop, setAvailableNextTrackInTop] = useState(true);

    const { previewLink, playerName, trackName } = getTrackInfo(playlist, currentTrackNumber);

    const nextTrackNumber = getNearNumber(currentTrackNumber, playlist.length, 1);

    const {
        trackName: nextTrackName,
        playerName: nextPlayerName,
        previewLink: nextPreviewLink,
    } = getTrackInfo(playlist, nextTrackNumber);

    const previewStyle = {
        backgroundImage: previewLink || `url(${placeholder})`,
        backgroundSize: previewLink ? 'cover' : 'contain',
        backgroundColor: '#dbdbdb',
        backgroundPositionY: '50%, 50%',
        backgroundPositionX: '50%, 50%',
        backgroundRepeat: 'no-repeat',
    };

    const nextPreviewStyle = {
        backgroundImage: nextPreviewLink || `url(${placeholder})`,
        backgroundSize: nextPreviewLink ? 'cover' : 'contain',
        backgroundColor: '#dbdbdb',
        backgroundPositionY: '50%, 50%',
        backgroundPositionX: '50%, 50%',
        backgroundRepeat: 'no-repeat',
    };

    const dispatch = useDispatch();

    const togglePlay = () => {
        if (!authorized) {
            document.location.href = AUTH_LINK_RU;
        }
        dispatch(setPlayerInfo({
            trackNumber: currentTrackNumber,
            topNumber: currentTopNumber,
            musicAlias: alias,
        }))
        if (isPlaying && alias === musicAlias) {
            handlePause();
        } else {
            handlePlay();
        }
    }

    const playNextTrack = () => {
        if (!authorized) {
            document.location.href = AUTH_LINK_RU;
        }
        handlePlay();
        if (alias === musicAlias) {
            if (currentTrackNumber === getNearNumber(currentTrackNumber, playlist.length, 1)) {
                const nextTopNumber = getNextTopNumber(currentTopNumber, musicTops);
                const nextMusicAlias = musicTops[nextTopNumber].alias;
                dispatch(setPlayerInfo({
                    trackNumber: 0,
                    musicAlias,
                    topNumber,
                }));
            } else {
                dispatch(setPlayerInfo({
                    trackNumber: getNearNumber(trackNumber, playlist.length, 1),
                    musicAlias,
                    topNumber,
                }));
                setCurrentTrackNumber(nextTrackNumber);
            }
        } else {
            dispatch(setPlayerInfo({
                trackNumber: nextTrackNumber,
                musicAlias: alias,
                topNumber: currentTopNumber,
            }))
        }
    }

    return (
        <div className={musicItemPreview()}>
            <div
                onClick={togglePlay}
                className={musicItemPreview('ImageWrapper')}
            >
                <LazyLoad scroll={true}>
                    <div style={previewStyle} className={musicItemPreview('Image')} />
                </LazyLoad>
                <div className={musicItemPreview('TrackInfoWrapper')}>
                    <div className={musicItemPreview('TrackInfo')}>
                        <div className={musicItemPreview('PlayerState')}>
                            {(isPlaying && alias === musicAlias) ? <Equalizer /> : <img src={play} alt='' />}
                        </div>
                        <div className={musicItemPreview('TrackInfoInner')}>
                            <div
                                className={musicItemPreview('TrackName')}>
                                <span
                                    className={musicItemPreview('TrackNameInner')}>
                                    {trackName}
                                </span>
                            </div>
                            <div
                                className={musicItemPreview('PlayerName')}
                            >
                                <span
                                    className={musicItemPreview('PlayerNameInner')}>
                                    {playerName}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={playNextTrack}
                className={musicItemPreview('NextTrackWrapper')}
            >
                <Translation>
                    {
                        (t, { i18n }) =>
                            <h3 className={musicItemPreview('NextTrackTitle')}>
                                {t('Следующий трек')}
                            </h3>
                    }
                </Translation>
                <div className={musicItemPreview('NextTrackInfo')}>
                    <div className={musicItemPreview('NextTrackInfoWrapper')}>
                        <div
                            className={classnames(musicItemPreview('NextTrackInfoInner',
                                {hidden: !availableNextTrackInTop}))}
                        >
                            <LazyLoad scroll={true}>
                                <div
                                    style={nextPreviewStyle}
                                    className={musicItemPreview('NextTrackPreviewWrapper')}
                                />
                            </LazyLoad>
                            <div className={musicItemPreview('NextTrackInfoText')}>
                                <div
                                    className={musicItemPreview('NextTrackName')}
                                >
                                    <span
                                        className={musicItemPreview('NextTrackNameInner')}>
                                        {nextTrackName}
                                    </span>
                                </div>
                                <div
                                    className={musicItemPreview('NextPlayerName')}
                                >
                                    <span
                                        className={musicItemPreview('NextPlayerNameInner')}>
                                        {nextPlayerName}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={arrow} className={musicItemPreview('Arrow')} alt='' />
                </div>
            </div>
        </div>
    );
};
